import React from 'react'
import PropTypes from 'prop-types'
import ShortHorizontalLine from '../../atoms/ShortHorizontalLine/ShortHorizontalLine'

const HeroWithImage = ({
  hasGreyBackground,
  hasDarkBackground,
  title,
  description,
  image,
  readMoreLink,
}) => {
  const sectionClasses = ['section', 'is-medium']
  if (hasGreyBackground) {
    sectionClasses.push('grey-background')
  }
  if (hasDarkBackground) {
    sectionClasses.push('has-background-dark has-text-white')
  }
  return (
    <section className={sectionClasses.join(' ')}>
      <div className="container">
        <div className="columns is-vcentered is-multiline">
          <div className="column is-4-desktop is-8-tablet is-offset-2-tablet is-offset-0-desktop is-12-mobile">
            <figure className="image is-5by4">
              <img
                src={
                  !!image.src.childImageSharp
                    ? image.src.childImageSharp.fluid.src
                    : image.src
                }
                alt={image.alt}
                style={{
                  borderRadius: '40px',
                }}
              />
            </figure>
          </div>
          <div className="column is-8 is-12-touch has-text-centered-mobile has-text-left-tablet mt-50-touch mt-0-mobile mb-38-mobile">
            <h3>{title}</h3>
            <ShortHorizontalLine className="my-4" isCenteredMobileOnly />
            {description && (
              <p className="body-small has-text-left">{description}</p>
            )}
            {readMoreLink && (
              <a href={readMoreLink}>
                <h3 className="has-text-primary mt-4">Read More ></h3>
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

HeroWithImage.propTypes = {
  hasDarkBackground: PropTypes.bool,
  hasGreyBackground: PropTypes.bool,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  readMoreLink: PropTypes.string,
}

export default HeroWithImage

import React from 'react'
import PropTypes from 'prop-types'
import { isAndroid, isIOS } from 'react-device-detect'
import appStoreBadge from '../../../img/appstore-badge.svg'
import googlePlayBadge from '../../../img/google-play-store.svg'
import ShortHorizontalLine from '../../atoms/ShortHorizontalLine/ShortHorizontalLine'
import Button from '../../atoms/Button/Button'

const DownloadButtons = ({
  downloadDescription,
  iosDownloadLink,
  androidDownloadLink,
}) => {
  if (isIOS) {
    return <Button href={iosDownloadLink}>Download for iOS</Button>
  }
  if (isAndroid) {
    return (
      <Button href={androidDownloadLink} disabled={!androidDownloadLink}>
        {androidDownloadLink ? 'Download for Android' : 'Coming soon'}
      </Button>
    )
  }
  return (
    <>
      <div className="columns my-1">
        <div className="column">
          <p className="body-small has-text-bold">{downloadDescription}</p>
        </div>
      </div>
      <div>
        {iosDownloadLink && (
          <a href={iosDownloadLink}>
            <img
              className="store-download-button"
              src={appStoreBadge}
              alt="Get it on App Store"
            />
          </a>
        )}
        {androidDownloadLink && (
          <a className="right-download-link" href={androidDownloadLink}>
            <img
              className="store-download-button"
              src={googlePlayBadge}
              alt="Get it on Google Play"
            />
          </a>
        )}
      </div>
    </>
  )
}

const MainSection = ({
  title,
  subtitle,
  bigImage,
  downloadDescription,
  androidDownloadLink,
  iosDownloadLink,
}) => {
  const titleWithLineBreaks = title.split('|').map((p) => (
    <>
      {p}
      <br />
    </>
  ))

  return (
    <section>
      <div className="columns is-flex-mobile is-multiline is-gapless has-text-white">
        <div className="column is-full-touch is-half-desktop half-section left order-2-touch has-background-grey-lighter">
          <div className="half-container is-flex is-vertical-center is-horizontal-center-touch">
            <div className="columns is-gapless">
              <div className="column is-full has-text-dark">
                <div className="columns is-gapless mb-1">
                  <div className="column">
                    <h1>{titleWithLineBreaks}</h1>
                  </div>
                </div>
                <ShortHorizontalLine className="my-4" isCenteredTouchOnly />
                <div className="columns mt-1">
                  <div className="column hero-text pt-0-touch">{subtitle}</div>
                </div>
                <DownloadButtons
                  downloadDescription={downloadDescription}
                  iosDownloadLink={iosDownloadLink}
                  androidDownloadLink={androidDownloadLink}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="column is-full-touch is-half-desktop half-section right order-1-touch has-background-grey-lighter">
          <div className="half-container is-flex is-vertical-center is-horizontal-center-touch">
            <figure className="image half-section-image">
              <img
                className={bigImage.isRounded ? 'is-rounded' : undefined}
                src={
                  !!bigImage.src.childImageSharp
                    ? bigImage.src.childImageSharp.fluid.src
                    : bigImage.src
                }
                alt={bigImage.alt}
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
  )
}

MainSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  downloadDescription: PropTypes.string,
  androidDownloadLink: PropTypes.string,
  iosDownloadLink: PropTypes.string,
  bigImage: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    isRounded: PropTypes.bool,
  }),
  logoImage: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
}

export default MainSection

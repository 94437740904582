import React from 'react'
import PropTypes from 'prop-types'
import ShortHorizontalLine from '../../atoms/ShortHorizontalLine/ShortHorizontalLine'
import IllustratedPoint from '../../molecules/IllustratedPoint/IllustratedPoint'

const IllustratedPointsSection = ({
  hasGreyBackground,
  title,
  description,
  items,
}) => {
  const sectionClasses = ['section', 'is-medium']
  if (hasGreyBackground) {
    sectionClasses.push('grey-background')
  }
  return (
    <section className={sectionClasses.join(' ')}>
      <div className="container">
        <div className="columns">
          <div className="column is-full mt-0 pt-0">
            <h2 className="has-text-centered">{title}</h2>
            <ShortHorizontalLine className="my-4" isCentered />
            {description && (
              <p className="body-large has-text-centered mb-5">{description}</p>
            )}
          </div>
        </div>
        <div className="columns is-multiline">
          {items.map((item) => (
            <IllustratedPoint
              image={item.image}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

IllustratedPointsSection.propTypes = {
  hasGreyBackground: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
    })
  ),
}

export default IllustratedPointsSection

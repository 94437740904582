import React from 'react'
import PropTypes from 'prop-types'

const Testimonials = ({ testimonials }) => {
  return (
    <section className="section is-medium light-pink-background">
      <div className="container">
        <div className="columns is-horizontal-center is-multiline">
          {testimonials.map(({ text, author }, index) => {
            const testimonialClass = ['column is-4 is-12-touch testimonial']
            if (index % 2 !== 0) {
              testimonialClass.push('is-offset-1-desktop')
            }
            return (
              <div className={testimonialClass.join(' ')}>
                <div className="testimonial-content">
                  <p className="like-h3">{text}</p>
                  <p className="body-small mt-4 author">{author}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      author: PropTypes.string,
    })
  ),
}

export default Testimonials

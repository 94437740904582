import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../Layout'

import IllustratedPointsSection from '../organisms/IllustratedPointsSection/IllustratedPointsSection'
import HeroWithImage from '../organisms/HeroWIthImage/HeroWithImage'
import QuoteWithLogo from '../organisms/QuoteWithLogo/QuoteWIthLogo'
import Testimonials from '../organisms/Testimonials/Testimonials'
import MainSection from '../organisms/MainSection/MainSection'
import HeroWithLogo from '../organisms/HeroWIthLogo/HeroWithLogo'
import StayInTouchSection from '../organisms/StayInTouchSection/StayInTouchSection'

export const IndexPageTemplate = ({
  mainSection,
  secondSection,
  thirdSection,
  fourthSection,
  heroWithImage,
  quoteWithImage,
  testimonials,
}) => {
  return (
    <div>
      <div className="columns">
        <div className="column has-text-centered has-background-dark has-text-white body-small has-text-bold mt-2">
          Launching soon: Carefully Co-op{' '}
          <a
            className="has-color-pink"
            href="https://carefully.coop/"
            target="_blank"
          >
            Sign Up >
          </a>
        </div>
      </div>
      <MainSection
        title={mainSection.title}
        subtitle={mainSection.subtitle}
        bigImage={mainSection.bigImage}
        downloadDescription={mainSection.downloadDescription}
        androidDownloadLink={mainSection.androidDownloadLink}
        iosDownloadLink={mainSection.iosDownloadLink}
      />
      <HeroWithLogo
        hasDarkBackground
        title={secondSection.title}
        subtitle={secondSection.subtitle}
        description={secondSection.description}
        link={secondSection.link}
        linkText={secondSection.linkText}
        image={{
          src: secondSection.image.src,
          alt: secondSection.image.alt,
        }}
      />
      <IllustratedPointsSection
        hasGreyBackground
        title={thirdSection.title}
        description={thirdSection.description}
        items={thirdSection.items}
      />
      <IllustratedPointsSection
        title={fourthSection.title}
        description={fourthSection.description}
        items={fourthSection.items}
      />
      <HeroWithImage
        hasGreyBackground
        title={heroWithImage.title}
        description={heroWithImage.description}
        readMoreLink={heroWithImage.readMoreLink}
        image={{
          src: heroWithImage.image.src,
          alt: heroWithImage.image.alt,
        }}
      />
      <QuoteWithLogo
        image={quoteWithImage.image.src}
        author={quoteWithImage.author}
        imageAlt={quoteWithImage.image.alt}
        quote={quoteWithImage.quote}
      />
      <Testimonials testimonials={testimonials} />
      <StayInTouchSection
        title="Stay in touch"
        description="We’d love to hear from you! Connect with us to learn more, join our mailing list, or inquire about press and partnerships."
      />
    </div>
  )
}

IndexPageTemplate.propTypes = {
  mainSection: PropTypes.shape({
    bigImage: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    }),
    downloadDescription: PropTypes.string,
    androidDownloadLink: PropTypes.string,
    iosDownloadLink: PropTypes.string,
  }),
  secondSection: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    }),
  }),
  thirdSection: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.array,
  }),
  heroWithImage: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    }),
    title: PropTypes.string,
    description: PropTypes.string,
    readMoreLink: PropTypes.string,
  }),
  quoteWithImage: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    }),
    author: PropTypes.string,
    quote: PropTypes.string,
  }),
  testimonials: PropTypes.array,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        mainSection={frontmatter.mainSection}
        secondSection={frontmatter.secondSection}
        thirdSection={frontmatter.thirdSection}
        fourthSection={frontmatter.fourthSection}
        heroWithImage={frontmatter.heroWithImage}
        quoteWithImage={frontmatter.quoteWithImage}
        logo={frontmatter.logo}
        testimonials={frontmatter.testimonials}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        mainSection {
          title
          subtitle
          bigImage {
            src {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          downloadDescription
          androidDownloadLink
          iosDownloadLink
        }
        secondSection {
          title
          subtitle
          description
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 170, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          link
          linkText
        }
        thirdSection {
          title
          description
          items {
            title
            description
            image {
              src {
                childImageSharp {
                  fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
        fourthSection {
          title
          items {
            title
            description
            image {
              src {
                childImageSharp {
                  fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
        heroWithImage {
          title
          description
          readMoreLink
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        quoteWithImage {
          author
          quote
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        testimonials {
          text
          author
        }
      }
    }
  }
`

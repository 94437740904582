import React from 'react'
import PropTypes from 'prop-types'
import ShortHorizontalLine from '../../atoms/ShortHorizontalLine/ShortHorizontalLine'

const HeroWithLogo = ({
  hasGreyBackground,
  hasDarkBackground,
  title,
  subtitle,
  description,
  image,
  link,
  linkText,
}) => {
  const sectionClasses = ['section', 'is-medium']
  if (hasGreyBackground) {
    sectionClasses.push('grey-background')
  }
  if (hasDarkBackground) {
    sectionClasses.push('has-background-dark has-text-white')
  }
  return (
    <section className={sectionClasses.join(' ')}>
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-2 is-12-touch is-horizontal-center is-flex-touch pb-0-touch">
            <figure className="image is-170px-wide">
              <img
                src={
                  !!image.src.childImageSharp
                    ? image.src.childImageSharp.fluid.src
                    : image.src
                }
                alt={image.alt}
              />
            </figure>
          </div>
          <div className="column is-10 is-12-touch has-text-centered-touch has-text-left-desktop mt-50-touch mt-23-mobile">
            <h2>{title}</h2>
            <ShortHorizontalLine className="my-4" isCenteredTouchOnly />
            {subtitle && <h3 className="mb-4">{subtitle}</h3>}
            {description && <p className="body-large">{description}</p>}
            <a href={link}>
              <h3 className="has-text-primary mt-4">{linkText} ></h3>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

HeroWithLogo.propTypes = {
  hasGreyBackground: PropTypes.bool,
  hasDarkBackground: PropTypes.bool,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
}

export default HeroWithLogo

import React from 'react'
import PropTypes from 'prop-types'
import ShortHorizontalLine from '../../atoms/ShortHorizontalLine/ShortHorizontalLine'

import './QuoteWithLogo.sass'

const QuoteWithLogo = ({ quote, author, image, imageAlt }) => {
  return (
    <section className="section has-background-dark">
      <div className="container">
        <div className="columns">
          <div className="column is-full is-flex is-horizontal-center">
            <figure
              className="image is-11x11rem"
              style={{ top: '-6.25rem', marginBottom: '-6.25rem' }}
            >
              <img
                src={
                  !!image.childImageSharp
                    ? image.childImageSharp.fluid.src
                    : image
                }
                alt={imageAlt}
              />
            </figure>
          </div>
        </div>
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h2 className="has-text-centered has-text-white">{quote}</h2>
            <ShortHorizontalLine className="my-4" isCentered />
            <p className="body-large quote-author">{author}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

QuoteWithLogo.propTypes = {
  quote: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  imageAlt: PropTypes.string,
  author: PropTypes.string,
}

export default QuoteWithLogo
